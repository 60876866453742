import { useEffect } from 'react';

import { useGroup, useIsTrial } from 'contexts/Authorization/Context';
import gql from 'graphql-tag';
import { useRouter } from 'next/router';
import { useLazyQuery } from 'utils/hooks/useLazyQuery';

export function ModuleAccess() {
  const currentGroup = useGroup();
  const { query, push } = useRouter();
  const { moduleUrl } = query;
  const isTrial = useIsTrial();

  const HAS_ACCESS = gql`
    query hasAccess($groupId: Int!, $userFlowUrl: String!) {
      userFlowGroupAccessQuery {
        hasAccess(groupId: $groupId, userFlowUrl: $userFlowUrl)
      }
    }
  `;

  const [executehasAccess] = useLazyQuery(HAS_ACCESS, {
    fetchPolicy: 'no-cache',
    onCompleted({ userFlowGroupAccessQuery: { hasAccess } }) {
      if (!hasAccess) {
        push('/');
      }
    },
  });

  useEffect(() => {
    if (!moduleUrl || isTrial) return;

    executehasAccess({
      variables: {
        groupId: currentGroup.id,
        userFlowUrl: String(moduleUrl),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleUrl]);

  return <></>;
}
