import React, { useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import { useGroup, useIsHandledByMatildaCentre, usePermissions, useUser } from 'contexts/Authorization/Context';
import { Crumb } from 'contexts/Breadcrumbs/Context';
import { TrackingProvider } from 'contexts/Tracking/Context';
import gql from 'graphql-tag';
import { Client } from 'graphql/client';
import isEmpty from 'lodash.isempty';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
import { getModuleCompletions } from 'utils/functions/getModuleCompletions';
import { getRole } from 'utils/functions/getRoleType';
import { getItem, removeItem, setItem } from 'utils/functions/localStorage';
import { useCurrentUserFlowForProject } from 'utils/hooks/useCurrentUserFlowForProject';
import { useLazyQuery } from 'utils/hooks/useLazyQuery';
import { useMutation } from 'utils/hooks/useMutation';
import { useProtectedRoute } from 'utils/hooks/useProtectedRoute';

import { Button, ButtonLink } from 'components/Shared/Inputs/Button';
import { Popup } from 'components/Shared/UI/Popup';

import { AudioPlayer } from './AudioPlayer/AudioPlayer';
import { BubbleTexts } from './BubbleTexts/BubbleTexts';
import CartoonAsset from './CartoonAsset';
import { USER_TYPE_LAYOUT_COMPONENT_MAP, MODULE_CSS_SUFFIX_MAP } from './CartoonContent.constants';
import { getQuizData } from './CartoonContent.helpers';
import { GetCartoonSlideQuery, GetUnitsQuery } from './CartoonContent.queries';
import { ProgressIndicator } from './ProgressIndicator/ProgressIndicator';
import { Quiz } from './Quiz/Quiz';
import { SlideNavigation } from './SlideNavigation/SlideNavigation';

const SEND_INTERNAL_NOTIFICATIONS = gql`
  mutation ($projectId: String!, $metadata: String!, $placeholders: [PlaceholdersInputType], $templateId: Int!, $userId: Int!) {
    notification {
      sendInternalNotifications(
        notificationGroup: {
          subject: "Module completed"
          projectId: $projectId
          templateId: $templateId
          notifications: { metadata: $metadata, userId: $userId, placeholders: $placeholders }
        }
      )
    }
  }
`;

type UserType = 'student' | 'teacher';

declare const InstallTrigger: any;

interface CartoonContentProps {
  baseCartoonUrl: string;
  baseUserFlowLessonUrl: string;
  baseUserFlowUrl: string;
  crumbs: Array<Crumb>;
  lessonNumber: string;
  userFlow: any;
  userFlowStep: any;
  userType: UserType;
}

export function CartoonContent({
  baseCartoonUrl,
  baseUserFlowLessonUrl,
  baseUserFlowUrl,
  crumbs,
  lessonNumber,
  userFlow,
  userFlowStep,
  userType,
}: CartoonContentProps) {
  useProtectedRoute(['Student', 'Teacher']);

  const group = useGroup();
  const roleType = getRole(usePermissions(), group.id);
  const howlerClient = useRef(Client('howler'));

  const router = useRouter();
  const user = useUser();

  const {
    push,
    query: { pageUrl, moduleUrl },
  } = router;

  const { title: moduleTitle } = userFlow;
  const {
    contentGroup: { contentPages: slides },
  } = userFlowStep;

  const DISABLE_BUTTON_DELAY_IN_MILLISECONDS = 2000;
  const isStudent = roleType === 'Student';

  const { Kanzi_Manual_Commands: kanzi } = window as any;

  const [areAllActivitiesCompleted, setAreAllActivitiesCompleted] = useState(false);
  const [cartoonAudioUrl, setCartoonAudioUrl] = useState(undefined);
  const [cartoonBubbleTexts, setCartoonBubbleTexts] = useState(undefined);
  const [cartoonImage, setCartoonImage] = useState(undefined);
  const [isAudioLoaded, setIsAudioLoaded] = useState(false);
  const [isAutoPlayEnabled, setIsAutoPlayEnabled] = useState(getItem('token') === 'module');
  const [hasAutoPlaySound, setHasAutoPlaySound] = useState(true);
  const [isNextSlideDisabled, setIsNextSlideDisabled] = useState(isStudent);
  const [isPreviousSlideDisabled, setIsPreviousSlideDisabled] = useState(isStudent);
  const [isAudioPlayerInformationPopupVisible, setIsAudioPlayerInformationPopupVisible] = useState(false);
  const [quizData, setQuizData] = useState(undefined);
  const [quizResponse, setQuizResponse] = useState(undefined);
  const [scrollY, setScrollY] = useState(0);
  const [isChangeCartoonImage, setIsChangeCartoonImage] = useState(false);
  const [dataUnit, setDataUnit] = useState<any>();
  const [isSkipPopupOpen, setIsSkipPopupOpen] = useState(false);
  const [isNextButtonPulsing, setIsNextButtonPulsing] = useState(false);

  const isFirefox = typeof InstallTrigger !== 'undefined';

  const SKIPPED_SLIDES = {
    alcohol: '/student/alcohol/alcohol-lesson-1-cartoon/in-janes-bedroom-getting-ready-10',
    'alcohol-and-cannabis':
      '/student/alcohol-and-cannabis/alcohol-cannabis-lesson-1-cartoon/scene-2-at-michaels-house-sitting-in-the-tv-room-watching-a-footy-game-9',
    'cannabis-and-psychostimulants':
      '/student/cannabis-and-psychostimulants/cannabis-psychostimulants-cartoon-1/lesson-1-at-sarahs-veranda-in-her-front-garden-slide-19',
    'mental-health': '/student/mental-health/mental-health-lesson-1-cartoon/scene-2-school-canteen-7',
    'mdma-and-emerging-drugs':
      '/student/mdma-and-emerging-drugs/mdma-&-emerging-drugs-lesson-1-cartoon-1/introduction-and-the-party-the-bus-stop-14',
  };

  const FIRST_SLIDES = [
    '132-slides-1',
    'scene-1-dans-intro-1-a',
    'introduction-and-the-party-1-4',
    'scene-1-narrator-intro-1',
    'lesson-1-hosts-intro-slide-1',
  ];

  const [executeSendNotifications] = useMutation(SEND_INTERNAL_NOTIFICATIONS, {
    client: howlerClient.current,
  });

  const [callGetCartoonSlide, { loading: isGetCartoonSlideLoading }] = useLazyQuery(GetCartoonSlideQuery, {
    onCompleted(response) {
      const {
        contentPage: { getContentPagePerName: contentPage },
      } = response;

      const { asset, audio } = contentPage;
      const { presignedUrl } = audio || {};
      const { bubbleTexts } = asset || {};

      const currentQuizData = getQuizData(bubbleTexts);

      setCartoonAudioUrl(presignedUrl);
      setCartoonBubbleTexts(bubbleTexts);
      setCartoonImage(asset);
      setIsNextSlideDisabled(!isEmpty(currentQuizData));
      setQuizData(currentQuizData);
    },
  });

  const [callGetUnits] = useLazyQuery(GetUnitsQuery, {
    onCompleted(response) {
      setDataUnit(response);
      const {
        unit: { get: units },
      } = response;

      const { userFlowSteps } = userFlow;

      const contentGroups = userFlowSteps.map((ufs) => {
        const { contentGroup, lastViewedContentPage } = ufs;
        const { contentPages } = contentGroup;

        const lastViewContentPageIndex = lastViewedContentPage
          ? contentPages.findIndex((contentPage) => contentPage.url === lastViewedContentPage.url)
          : 0;
        const completionPercentage = lastViewContentPageIndex / (contentPages.length - 1);

        return {
          ...contentGroup,
          completionPercentage,
        };
      });

      const unitContentGroupIds = units.map((unit) => unit.contentGroup.id);

      const activityContentGroups = contentGroups.filter((contentGroup) => {
        const { id, type } = contentGroup;

        const hasAccess = unitContentGroupIds.includes(id);
        const isActivity = type === 'ACTIVITY';

        return hasAccess && isActivity;
      });

      setAreAllActivitiesCompleted(activityContentGroups.every((activityContentGroup) => activityContentGroup.completionPercentage === 1));
    },
  });

  const onCharacterBackStorySkip = () => {
    const url = SKIPPED_SLIDES[String(moduleUrl)];
    setIsSkipPopupOpen(false);
    push(url);
  };

  const onPopupClose = () => {
    setIsSkipPopupOpen(false);
    callGetCartoonSlide({
      variables: {
        pageUrl,
      },
    });
  };

  const isFirstSlideOfFirstLesson = () => {
    if (!pageUrl || !lessonNumber) return false;
    if (!FIRST_SLIDES.includes(String(pageUrl))) return false;
    return true;
  };

  const handleAudioLoaded = () => {
    setIsAudioLoaded(true);
  };

  const handleAutoPlayChange = () => {
    setIsAutoPlayEnabled((previous) => !previous);
  };

  const handleAutoPlayMute = () => {
    setHasAutoPlaySound((previous) => !previous);
  };

  const handleAutoPlayInfoDisplay = () => {
    setIsAudioPlayerInformationPopupVisible((previous) => !previous);
  };

  const handleQuizChange = (response) => {
    setQuizResponse(response);

    setIsNextSlideDisabled(!response);
  };

  const handleRouteChange = (currentUrl: string) => {
    setIsNextButtonPulsing(false);
    setQuizResponse(undefined);
    setScrollY(window.scrollY);

    router.push(
      {
        pathname: `${baseCartoonUrl}/${currentUrl}`,
      },
      undefined,
      { scroll: false },
    );
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, scrollY);
    }, 10);

    if (isAutoPlayEnabled) {
      setIsAutoPlayEnabled(kanzi?.CurrentLanguage() === 'en');
    }
    if (hasAutoPlaySound) {
      setHasAutoPlaySound(kanzi?.CurrentLanguage() === 'en');
    }
  }, [pageUrl]);

  const handlePreviousSlide = (currentUrl: string) => {
    setIsNextButtonPulsing(false);
    setIsChangeCartoonImage(true);
    handleRouteChange(currentUrl);
  };

  function handleSlideCompletedNavigation(completeContentPageTrackingHandler, nextSlideUrl) {
    setIsNextButtonPulsing(false);
    completeContentPageTrackingHandler({
      onCompleted: () => {
        setIsChangeCartoonImage(true);
        handleRouteChange(nextSlideUrl);
      },
    });
  }

  const handleStopAndThinkChange = (value: string) => {
    setIsNextSlideDisabled(value === '');
  };

  const currentSlideIndex = slides.findIndex((page) => page.url === pageUrl);

  const isFirstSlide = currentSlideIndex === 0;
  const isLastSlide = currentSlideIndex === slides.length - 1;

  const nextSlide = slides[currentSlideIndex + 1];
  const previousSlide = slides[currentSlideIndex - 1];

  useEffect(() => {
    if ((!isFirstSlide && !slides[currentSlideIndex - 1]?.hasBeenViewed && isStudent) || currentSlideIndex === -1) {
      handleRouteChange(slides[0].url);
    }
  }, []);

  useEffect(() => {
    if (!pageUrl) {
      return;
    }

    if (isFirstSlideOfFirstLesson()) {
      setIsSkipPopupOpen(true);
    } else {
      callGetCartoonSlide({
        variables: {
          pageUrl,
        },
      });
    }

    // NOTE: This is a temp fix for the audio autoplay block issue for Firefox
    if (!isFirefox) {
      return;
    }

    const reloadCount = Number(getItem('reloadCount'));
    if (reloadCount < 1) {
      setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      removeItem('reloadCount');
    }
  }, [pageUrl]);

  useEffect(() => {
    if (!cartoonBubbleTexts) {
      return;
    }

    const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
    const handleDelayEnableNextPrevNavButtons = async () => {
      setIsNextSlideDisabled(true);

      if (isStudent) {
        setIsPreviousSlideDisabled(true);
        await delay(DISABLE_BUTTON_DELAY_IN_MILLISECONDS);
      }

      if (!isEmpty(cartoonBubbleTexts)) {
        const [firstItem] = cartoonBubbleTexts;
        const { shape } = firstItem;

        setIsNextSlideDisabled(shape === 'STOP_AND_THINK');
      } else {
        setIsNextSlideDisabled(false);
      }

      setIsPreviousSlideDisabled(false);
    };

    handleDelayEnableNextPrevNavButtons();
  }, [cartoonBubbleTexts]);

  useEffect(() => {
    if (!isLastSlide || !user.unit) {
      return;
    }

    if (!user.unit) return;
    callGetUnits({
      variables: {
        unitId: user.unit.id,
      },
    });
  }, [isLastSlide]);

  useEffect(() => {
    removeItem('token');
  }, []);

  useEffect(() => {
    if (cartoonAudioUrl) return;

    setTimeout(() => {
      setIsNextButtonPulsing(true);
    }, 6000);

  }, [cartoonAudioUrl])

  const hasCartoonAudio = Boolean(cartoonAudioUrl);
  const hasCartoonImage = Boolean(cartoonImage);
  const hasQuizResponse = Boolean(quizResponse);

  const isDesktopDevice = useMediaQuery({ query: '(min-width: 1200px)' });
  const isLargerDevice = useMediaQuery({ query: '(min-width: 768px)' });
  const isSmallerDevice = useMediaQuery({ query: '(max-width: 500px)' });

  const isBubbleTextVisibleOnLargerDevice = cartoonImage && isLargerDevice && !quizData;
  const isBubbleTextVisibleOnSmallerDevice = cartoonImage && !isLargerDevice && !quizData;
  const isCartoonImageVisible = hasCartoonAudio ? hasCartoonImage && hasCartoonAudio && isAudioLoaded : hasCartoonImage;

  /* areAllActivitiesCompleted check is removed based off the business decision
     We are not checking activity completion within lesson completion process
  */
  const canCompleteLesson = isLastSlide && hasQuizResponse && roleType === 'Student';

  const Layout = USER_TYPE_LAYOUT_COMPONENT_MAP[userType];

  return (
    <Layout crumbs={crumbs} description="Cartoon slider" title={`${moduleTitle} cartoon`}>
      <section className="l-full-width l-position--relative kanzi-exclude-speech">
        <img
          alt=""
          className="l-position--centred h-with-padding h-desktop-only p-student__thumbpin"
          src="/images/thumbpin-group-secondary.svg"
        />
        <div className="l-container l-container--medium l-flex l-flex--justify-space-between l-flex--align-center h-no-y-padding">
          <h1 className="c-typography--special mr-6xl">{moduleTitle}</h1>

          <ProgressIndicator current={currentSlideIndex + 1} total={slides.length} />
        </div>
      </section>
      {/* This is a temp fix for PDF image shift issue for larger devices  */}
      <section
        className={cx('l-position--relative h-background--color-grey-300', {
          'l-fixed-full-height': !isDesktopDevice,
          'l-fixed-large-device-height': isDesktopDevice,
        })}
      >
        <div className="l-container l-container--medium l-position--relative h-with-2x-large-y-padding h-with-3x-large-x-padding">
          <TrackingProvider>
            {({ completeContentPageTracking, completeUserFlowStepTracking, stepTrackingId, stepId, isCompleted }) => {
              const mustPerformedGetUserFlow = Boolean(isLastSlide) && Boolean(stepTrackingId) && !isCompleted;
              const { selectedUserFlow: selectedModule } = useCurrentUserFlowForProject(mustPerformedGetUserFlow, false);
              if (selectedModule) {
                const lessons = getModuleCompletions(selectedModule, dataUnit?.unit);
                if (lessons.every((r) => r.userStatus === 'COMPLETED'))
                  executeSendNotifications({
                    variables: {
                      projectId: process.env.REACT_APP_PROJECT_ID,
                      templateId: process.env.REACT_APP_TEMPLATE_MODULE_COMPLETED,
                      metadata: `{\"title\":\"${selectedModule.title}\",\"type\":\"ModuleCompleted\"}`,
                      userId: user.id,
                      placeholders: [{ key: '[UserFlowName]', value: selectedModule.title }],
                    },
                  });
              }

              // eslint-disable-next-line
              useEffect(() => {
                if (isLastSlide && stepTrackingId) {
                  completeUserFlowStepTracking();
                }
              }, [isLastSlide]);

              return (
                <>
                  <div className="l-position--relative h-with-y-margin">
                    <div
                      className={`l-position--relative l-flex l-flex--align-center ${quizData && 'c-cartoon--quiz'} c-cartoon-${
                        MODULE_CSS_SUFFIX_MAP[moduleTitle]
                      }`}
                    >
                      {quizData && (
                        <Quiz
                          data={quizData}
                          isCartoonImageLoading={isGetCartoonSlideLoading}
                          onResponseChangeHandler={handleQuizChange}
                          response={quizResponse}
                        />
                      )}
                      {isBubbleTextVisibleOnLargerDevice && isCartoonImageVisible && (
                        <BubbleTexts
                          isCartoonImageLoading={isChangeCartoonImage}
                          isLargerDevice={isLargerDevice}
                          items={cartoonBubbleTexts}
                          onStopAndThinkChangeHandler={handleStopAndThinkChange}
                        />
                      )}
                      {!isFirstSlide && (
                        <SlideNavigation
                          isDisabled={isPreviousSlideDisabled}
                          onClickHandler={() => handlePreviousSlide(previousSlide.url)}
                          isPrevious
                        />
                      )}
                      {isCartoonImageVisible && (
                        <CartoonAsset
                          asset={cartoonImage}
                          items={cartoonBubbleTexts}
                          isLargerDevice={isLargerDevice}
                          onImageLoaded={() => {
                            setIsChangeCartoonImage(false);
                          }}
                          isLoading={isChangeCartoonImage}
                        />
                      )}
                      {!isLastSlide && !isSkipPopupOpen && (
                        <SlideNavigation
                          isPulsing={isNextButtonPulsing}
                          isDisabled={isNextSlideDisabled}
                          onClickHandler={() => handleSlideCompletedNavigation(completeContentPageTracking, nextSlide.url)}
                        />
                      )}
                    </div>
                  </div>

                  {isBubbleTextVisibleOnSmallerDevice && isCartoonImageVisible && (
                    <BubbleTexts
                      isCartoonImageLoading={isChangeCartoonImage}
                      isLargerDevice={isLargerDevice}
                      items={cartoonBubbleTexts}
                      onStopAndThinkChangeHandler={handleStopAndThinkChange}
                    />
                  )}
                  {isSkipPopupOpen && (
                    <Popup onClickClosePopup={onPopupClose}>
                      <p>
                        Most students are keen to learn more about the characters by reading their backstories, to do this select
                        <strong> Continue</strong>. If you&apos;d prefer to jump straight into the lesson instead, select{' '}
                        <strong>Skip</strong>.
                      </p>
                      <div className="popup-skip-cartoon-container">
                        <Button onClick={onPopupClose} color="secondary">
                          Continue
                        </Button>
                        <Button onClick={onCharacterBackStorySkip} filled color="primary">
                          Skip
                        </Button>
                      </div>
                    </Popup>
                  )}

                  {cartoonAudioUrl && hasCartoonImage && !isChangeCartoonImage && (
                    <div className=" l-flex l-flex--align-center l-flex-gap h-with-4xl-margin-bottom">
                      <div className="l-flex l-flex--align-center">
                        <AudioPlayer
                          isAutoPlayEnabled={isAutoPlayEnabled}
                          isAudioPlayerInformationPopupVisible={isAudioPlayerInformationPopupVisible}
                          hasAutoPlaySound={hasAutoPlaySound}
                          isNextSlideDisabled={isNextSlideDisabled}
                          onAutoPlayChangeHandler={handleAutoPlayChange}
                          onAutoPlayInfoDisplay={handleAutoPlayInfoDisplay}
                          onAutoPlayMuteHandler={handleAutoPlayMute}
                          onEndHandler={() => {
                            setIsNextButtonPulsing(true);
                            if (!isAutoPlayEnabled) {
                              return;
                            }

                            handleSlideCompletedNavigation(completeContentPageTracking, nextSlide.url);
                          }}
                          onLoadedHandler={handleAudioLoaded}
                          roleType={roleType}
                          source={cartoonAudioUrl}
                        />
                      </div>

                      <ButtonLink
                        className={`l-flex__item--row h-with-4xl-margin-bottom kanzi-exclude-speech l-position-h-${
                          isSmallerDevice ? 'center' : 'right'
                        }`}
                        href={baseUserFlowUrl}
                        icon="arrow"
                        iconPosition="left"
                        rotateIcon="90"
                      >
                        Save progress and return later
                      </ButtonLink>
                    </div>
                  )}
                </>
              );
            }}
          </TrackingProvider>

          {!(cartoonAudioUrl && hasCartoonImage && !isChangeCartoonImage) && (
            <ButtonLink
              className={`l-flex__item--row h-with-4xl-margin-bottom kanzi-exclude-speech l-position-h-${
                isSmallerDevice ? 'center' : 'right'
              }`}
              href={baseUserFlowUrl}
              icon="arrow"
              iconPosition="left"
              rotateIcon="90"
            >
              Save progress and return later
            </ButtonLink>
          )}

          {isLastSlide && roleType === 'Teacher' && (
            <ButtonLink
              className={`l-flex__item--row h-with-4xl-margin-bottom kanzi-exclude-speech l-position-h-${
                isSmallerDevice ? 'center' : 'right'
              }`}
              href="/teacher"
              icon="arrow"
              iconPosition="left"
              rotateIcon="90"
            >
              Return to dashboard
            </ButtonLink>
          )}

          {canCompleteLesson && roleType === 'Student' && (
            <ButtonLink
              className={`l-flex__item--row ${isSmallerDevice ? 'center' : 'right'}`}
              href={`${baseUserFlowLessonUrl}/completion-success/${lessonNumber}`}
              icon="arrow"
              iconPosition="right"
              rotateIcon="270"
            >
              Complete lesson
            </ButtonLink>
          )}
        </div>
      </section>
    </Layout>
  );
}
